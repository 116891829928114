import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner } from "../containers/common"
import { Form } from "../containers/suggestArticle"

const SuggestArticlePage = ({ data }) => {
  const {
    ogtitle,
    ogdescription,
    title,
    details,
    formDetails,
  } = data.suggestArticle
  return (
    <Layout>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner title={title} description={details} />
      <Form data={formDetails} />
    </Layout>
  )
}

export const query = graphql`
  query SuggestArticlePageQuery {
    suggestArticle: contentfulSuggestArticlePage {
      ogtitle
      ogdescription
      title
      details
      formDetails
    }
  }
`

export default SuggestArticlePage
