import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from "draft-js"
import Swal from "sweetalert2"

// Components
import { Input, Dropdown, Textarea } from "../../../components/inputFields"

// Stylesheet
import styles from "./index.module.scss"

// Images
import loader from "../../../images/loading.svg"

const ArticleSubmissionSchema = Yup.object().shape({
  articleLink: Yup.string().url().required("Required"),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
})

const ArticleForm = ({ data }) => {
  const [category, setCategory] = useState("Select Category")
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const categoryToggle = option => {
    setCategory(option)
  }

  const formSubmit = async (values, onSubmitProps) => {
    if (values && category !== "Select Category" && description !== "") {
      setError(false)
      setLoading(true)
      const { articleLink, name, email } = values
      const rawContentState = convertToRaw(description.getCurrentContent())

      try {
        const fetchTitle = await axios
          .post("/.netlify/functions/get-og-image", JSON.stringify(articleLink))
          .catch(e => {
            throw e
          })

        const articleSubmit = await axios.post(
          "/.netlify/functions/article-submission",
          {
            title: fetchTitle.data.metaInfo.title || "Tribute to mom",
            imageUrl: fetchTitle.data.metaInfo.image,
            articleLink,
            name,
            email,
            description: draftToHtml(rawContentState),
            category,
          }
        )
        if (articleSubmit.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Successful!",
            text: articleSubmit.data.message,
            showConfirmButton: false,
            timer: 3000,
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          })
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Something's wrong with the URL that you provided. Please try again with a working URL!",
        })
      }

      onSubmitProps.resetForm()
      setCategory("Select Category")
      setDescription("")
      setLoading(false)
    } else {
      setError(true)
    }
  }

  return (
    <div className={styles.articleForm}>
      <div className="max-w-3xl mx-auto px-4 xl:px-0">
        <p className={styles.note}>{data}</p>
        <Formik
          initialValues={{
            articleLink: "",
            name: "",
            email: "",
          }}
          validationSchema={ArticleSubmissionSchema}
          onSubmit={formSubmit}
        >
          {({ errors, touched, validateForm }) => (
            <Form className="mt-8">
              <h4 className={styles.formSectionTitle}>Article Information</h4>
              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4">
                <Input
                  label="Article Link"
                  required
                  type="url"
                  placeholder="https://www.example.com"
                  name="articleLink"
                  error={
                    errors.articleLink && touched.articleLink ? true : false
                  }
                  disabled={isLoading}
                  classes="col-span-2 flex flex-col items-start"
                />
                <Dropdown
                  label="Category"
                  required
                  classes="col-span-1 flex flex-col items-start"
                  defaultValue={category}
                  error={error && category === "Select Category"}
                  options={[
                    "#EnjoyingMotherhood",
                    "#KeepingTheRomance",
                    "#OldFashionedTipsThatWork",
                    "#QuickAndEasyRecipes",
                  ]}
                  changeSelected={val => categoryToggle(val)}
                />
              </div>
              <Textarea
                label="Give us the why"
                required
                classes="flex flex-col items-start mb-6"
                placeholder="Why do you like this article?
            Why is it beneficial to moms?"
                rows="6"
                name="description"
                error={errors.description && touched.description ? true : false}
                disabled={isLoading}
                description={description}
                setDescription={setDescription}
              />

              <h4 className={styles.formSectionTitle}>
                Your Contact Information
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4">
                <Input
                  label="Your Name"
                  required={true}
                  type="text"
                  placeholder="Your Name..."
                  name="name"
                  error={errors.name && touched.name ? true : false}
                  disabled={isLoading}
                  classes="col-span-1 flex flex-col items-start"
                />
                <Input
                  label="Your Email Address"
                  required={true}
                  type="email"
                  placeholder="Email..."
                  name="email"
                  error={errors.email && touched.email ? true : false}
                  disabled={isLoading}
                  classes="col-span-1 flex flex-col items-start"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4">
                <button
                  className={`${styles.submitBtn} mt-8 btn twine-gradient`}
                  type="submit"
                  disabled={isLoading}
                  onClick={() => validateForm().then(() => formSubmit())}
                >
                  {isLoading ? (
                    <img src={loader} className="mx-auto w-6" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ArticleForm
